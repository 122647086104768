import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import styles from './legal.module.sass'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import LegalNav from '../../components/legal-nav'

const DisclaimersPage = () => (
   <Layout>
      <SEO title="Disclaimers + Disclosures" />
      <div className={['page', styles.page__legal].join(' ')}>
         <LegalNav className={styles.legal__nav}></LegalNav>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Disclaimers + Disclosures
            </h1>
         </div>
         <div className={styles.legal__content}>
            <p>
               Investment advisory services offered through SPC Financial
               <sup>&reg;</sup> (SPC).
            </p>

            <a id="tax" className={styles.anchor}></a>
            <p>
               Registration with the SEC as an investment adviser does not imply
               a certain level of skill training or endorsement by the SEC.
            </p>
            <p>
               SPC provides tax preparation services and analysis through a separate engagement letter with clients
            </p>



            <p>
               Professional Designation Glossary explaining the accrediting
               organizations and requirements for all certifications listed on our website can be found <a target='_blank' href='https://assets.ctfassets.net/wt3nvc89w481/tO07y08QQnZ4qbIuOAOiO/79c0b49bb6916b902fbf6b3e09f7f03d/Professional_Designation_Glossary_-_082022.pdf'><b>here</b></a> .
            </p>

            <a id="links" className={styles.anchor}></a>
            <p>
               Third-party links are being provided for informational purposes
               only. SPC is not affiliated with and does not endorse,
               authorize, sponsor, verify, or monitor any of the listed websites
               or their respective sponsors, and SPC is not responsible or
               liable for the content of any website, or the collection or use
               of information regarding any website's users and/or members.
               Links are believed to be accurate at time of dissemination but we
               make no guarantee, expressed or implied, to the accuracy of the
               links subsequently.
            </p>

            <a id="aum" className={styles.anchor}></a>
            <p>
               <sup>1</sup>
               <b>Assets under advisement and management</b>: accurate as of
               December 31, 2023.
            </p>

            <p>
               Some icons made by various authors from{' '}
               <OutboundLink
                  href="//flaticon.com"
                  rel="noopener noreferrer"
                  target="_blank">
                  www.flaticon.com
               </OutboundLink>
               .
            </p>
         </div>
      </div>
   </Layout>
)

export default DisclaimersPage
